<template>
  <div class="my-flex-container-column">
    <lines-filter @active-change="filterActive = $event" :filter-model="filterModel" @clear-filters="clearFilters"/>
    <lines-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import linesFilter from '@/views/Nsi/Lines/LinesFilter';
import linesTable from '@/views/Nsi/Lines/LinesTable';

export default {
  name: 'Lines',
  components: { linesTable, linesFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        energyObjectIdFilter: '',
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        primaryEquipmentTypeIdFilter: [1],
        skipEoObject: false,
        isLocalNsiFilter: false,
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        eoRegionFilter: []
      },
      filterActive: true
    };
  },
  methods: {
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        energyObjectIdFilter: '',
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        primaryEquipmentTypeIdFilter: [1],
        skipEoObject: false,
        isLocalNsiFilter: false,
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        eoRegionFilter: []
      };
    }
  }
};
</script>

<style scoped>

</style>
