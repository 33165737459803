<template>
  <div class="my-flex-container-column">
    <el-row class="m-0" :gutter="20">
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline"
                   :disabled="mergeMode">Добавить
        </el-button>
      </el-col>
      <el-col :span="3">
      <el-button @click="handleDownLoad" type="text" 
                  :loading="loading"
                  icon="el-icon-download">Выгрузить
        </el-button>
      </el-col>
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleMergeMode" type="text" icon="el-icon-share">Объединить</el-button>
      </el-col>
      <el-col v-if="userCanEdit && mergeMode" :span="3">
        <el-button @click="handleMergeModeCancel" type="text" icon="el-icon-circle-close">Отменить
        </el-button>
      </el-col>
      <el-col :span="12">
        <pagination style="text-align: center"
                    :total-count="totalCount"
                    :page-size.sync="pageSize"
                    :current-page.sync="pageNumber"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"/>
      </el-col>
    </el-row>
    <div>
      <el-button @click="this.elTreeCollapse" type="text" class="ml-2">Свернуть/развернуть всё
      </el-button>
      <!-- <span v-if="!this.filterModel.skipEoObject"
            class="total_items">Всего: {{ this.linesCount }}</span> -->
    </div>
    <div v-loading="loading" class="my-flex-data-content">

      <el-tree :data="primaryEquipments"
               default-expand-all
               node-key="id"
               ref="tree">
          <span class="custom-tree-node" slot-scope="{ node }">
            <div class="tdWraper">
              <span>
                <el-button type="text" icon="el-icon-search"
                           v-if="(node.level == 2 || !node.data.children) && !mergeMode"
                           @click="handleView(node.$index, node.data.id)"></el-button>
                <el-checkbox v-if="(node.level == 2 || !node.data.children) && mergeMode"
                             v-model="node.data.isMerging"
                             @change="handleIsMergingChange(node.data)"></el-checkbox>
              </span>
              <span>
                <i v-if="node.level == 1 && node.data.children"><factoryIcon/></i>
                <i v-if="node.level == 2 || !node.data.children"><settingIcon/></i>
              </span>
              <span>{{ node.data.name }}</span>
            </div>
          </span>
      </el-tree>

      <line-modal @on-update="getPrimaryEquipments" @on-create="getPrimaryEquipments()"
                  @on-delete="getPrimaryEquipments()"
                  v-model="dialogVisible" :primaryEquipment="dialogPrimaryEquipment"></line-modal>
      <lines-merge-modal v-model="dialogMergeVisible" :items="mergingItems"
                         @on-merge="handleMerge($event)"
                         @on-merge-item-remove="handleMergeItemRemove($event)"></lines-merge-modal>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import primaryEquipmentsApi from '@/api/nsi/primaryEquipments';
import xlsUpDownHelper from '@/mixins/xlsUpDownHelper';
import reportsApi from '@/api/reports';
import io from '@/utilities/fileIO';
import pagination from '@/components/Ui/Pagination';
import factoryIcon from '@/assets/icons/factory.svg';
import settingIcon from '@/assets/icons/settings.svg';
import treeHelper from '@/mixins/treeHelper';
import lineModal from './LineModal';
import LinesMergeModal from './LinesMergeModal';

export default {
  name: 'LinesTable',
  mixins: [treeHelper, xlsUpDownHelper],
  props: {
    filterModel: {
      type: Object,
default: () => {
      }
    },
    filterActive: { type: Boolean, default: () => true },
  },
  components: {
    pagination,
    lineModal,
    factoryIcon,
    settingIcon,
    LinesMergeModal
  },
  data() {
    return {
      expand: false,
      loading: false,
      primaryEquipments: [],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      dialogPrimaryEquipment: {},
      mergeMode: false,
      mergingItems: [],
      dialogMergeVisible: false,
      linesCount: 0,
    };
  },
  watch: {
    filterModel: {
      handler() {
        // console.log('this.$refs.tree', this.$refs.tree);
        // this.$refs.tree.defaultExpandAll = true;
        this.getPrimaryEquipments();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
  },
  async mounted() {
    // this.$nextTick(() => {
    //   this.tableHeight = this.getTableHeight();
    // });
    await this.getPrimaryEquipments();
    //   this.tableHeight = this.getTableHeight();
  },
  methods: {
    async handleDownLoad() {
      this.loading = true;
      const params = {
        hideLines: false,
        nameFilter: this.filterModel.nameFilter,
        energyObjectIdFilter: this.filterModel.energyObjectIdFilter,
        duOrganizationName: this.filterModel.duOrganizationName,
        dvOrganizationName: this.filterModel.dvOrganizationName,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        isRuFilter: this.filterModel.isRuFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter
      };
      // const res = await primaryEquipmentsApi.getLinesWithPagination(params);
      // if (res.data) {
        // await this.exportToExcelTree('ЛЭП', res.data.items);
      // }
      const res = await reportsApi.createPrimaryEquipmentsReport(params);
      if (res.data.exceptionDetails) {
        this.$alert(`При формировании отчета произошла ошибка: ${res.data.exceptionDetails}`, 'Ошибка');
      } else {
        io.downloadFile(res.data.content, res.data.contentType, res.data.fileName);
      }
        this.loading = false;
    },
    async getPrimaryEquipments() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        nameFilter: this.filterModel.nameFilter,
        energyObjectIdFilter: this.filterModel.energyObjectIdFilter,
        duOrganizationName: this.filterModel.duOrganizationName,
        dvOrganizationName: this.filterModel.dvOrganizationName,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        isRuFilter: this.filterModel.isRuFilter,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter
      };
      const res = await primaryEquipmentsApi.getLinesWithPagination(params);
      if (res.data) {
        this.primaryEquipments = res.data.items;
        this.totalCount = res.data.totalCount;
        this.linesCount = res.data.treeItemsCount;
        this.defaultExpandedKeys = this.primaryEquipments.map((i) => i.id);

        this.applyIsMerging();
        this.loading = false;
      }
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getPrimaryEquipments();
    },
    async handleCurrentChange() {
      await this.getPrimaryEquipments();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getPrimaryEquipments();
    },
    async handleView(index, id) {
      const res = await primaryEquipmentsApi.getPrimaryEquipmentItem(id);
      this.dialogPrimaryEquipment = res.data;
      this.dialogVisible = true;
    },
    // eslint-disable-next-line no-unused-vars
    handleCreate(index, primaryEquipment) {
      this.dialogPrimaryEquipment = {
        id: null,
        name: null,
        productAssetModelId: '',
        energyObjectIds: [],
        energyObjectIdsInit: [],
        // normallyInService: false,
        ownerOrganizations: [],
        technologicalAdministrationOrganizations: [],
        technologicalManagementOrganizations: [],
        dispatchAdministrationOrganizations: [],
        dispatchManagementOrganizations: [],
        isAip: false
      };
      this.dialogVisible = true;
      this.$root.$emit('addEvent', {
        editMode: true,
      });
    },
    applyIsMerging() {
      for (let i = 0; i < this.primaryEquipments.length; i++) {
        if (this.primaryEquipments[i].children) {
          for (let j = 0; j < this.primaryEquipments[i].children.length; j++) {
            const item = this.primaryEquipments[i].children[j];
            this.$set(item, 'isMerging', this.mergingItems.some((x) => x.id === item.id));
          }
        }
      }
    },
    applyIsMergingToSiblings(id, isMerging) {
      for (let i = 0; i < this.primaryEquipments.length; i++) {
        if (this.primaryEquipments[i].children) {
          for (let j = 0; j < this.primaryEquipments[i].children.length; j++) {
            const item = this.primaryEquipments[i].children[j];
            if (item.id === id) {
              this.$set(item, 'isMerging', isMerging);
            }
          }
        }
      }
    },
    handleMergeItemRemove(item) {
      const index = this.mergingItems.findIndex((x) => x.id === item.id);
      if (index > -1) {
        this.mergingItems.splice(index, 1);
        this.applyIsMerging();

        if (this.mergingItems.length <= 1) {
          this.dialogMergeVisible = false;
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        }
      }
    },
    async handleMerge(data) {
      if (data.secondaryItems.some((x) => x.isAip)) {
        this.$alert('Невозможно объединить несколько корпоративных записей или использовать корпоративную запись в качестве присоединяемой', 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
        return;
      }

      this.loading = true;

      const res = await primaryEquipmentsApi.mergePrimaryEquipments(data.mainItem.id, data.secondaryItems.map((x) => x.id));
      if (!res.data.succeeded) {
        this.$alert(res.data.resultMessage, 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
      } else {
        this.dialogMergeVisible = false;
        this.handleMergeModeCancel();
        await this.getPrimaryEquipments();
      }
      this.loading = false;
    },
    handleIsMergingChange(item) {
      if (!this.mergingItems.some((x) => x.id === item.id)) {
        this.mergingItems.push(item);
        this.applyIsMergingToSiblings(item.id, true);
      } else {
        const index = this.mergingItems.findIndex((x) => x.id === item.id);
        if (index > -1) {
          this.mergingItems.splice(index, 1);
          this.applyIsMergingToSiblings(item.id, false);
        }
      }
    },
    handleMergeMode() {
      if (!this.mergeMode) {
        this.mergeMode = true;
      } else {
        if (this.mergingItems.length <= 1) {
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          this.dialogMergeVisible = true;
        }
      }
    },
    handleMergeModeCancel() {
      this.mergingItems.splice(0);
      this.applyIsMerging();
      this.mergeMode = false;
    }
  }
};
</script>

<style scoped>

.total_items {
  float: right;
  color: #909399;
  font-family: Montserrat-Regular;
  font-size: 14px;
  margin-top: 12px;
  margin-right: 8px;
}
</style>
